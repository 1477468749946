<template>
	<div class="index-container">
		<van-nav-bar
			:title="title"
			left-text="返回"
			left-arrow
			@click-left="navBack"
		/>
		
		<van-cell-group class="receive_detail">
			<van-cell title="维修单号" :value="deviceInfo.base_order_no" />
			<van-cell title="设备名称" :value="deviceInfo.device_name" />
			<van-cell title="设备编码	" :value="deviceInfo.device_code" />
			<van-cell title="使用部门	" :value="deviceInfo.device_dept_name" />
			<van-cell title="使用状况	" :value="deviceInfo.status" />
      <van-cell title="报修人" :value="repairUserInfo.name" />
      <van-cell title="报修人电话" :value="repairUserInfo.mobile" />
      <van-cell title="故障描述" :value="deviceInfo.description" />
		</van-cell-group>
	
		<van-form @submit="onSubmit" class="receive_content">	
			<van-field
				v-if="is_programme_show"
				v-model="programme"
				rows="2"
				autosize
				type="textarea"
				maxlength="200"
				placeholder="请输入维修内容"
				show-word-limit
			/>
			<div style="margin: 16px;" v-if="is_show">
				<van-button round block type="info" native-type="submit">竣工</van-button>
			</div>
		</van-form>
		<van-form @submit="quickOrderReceive" class="receive_content">
			<div style="margin: 16px;" v-if="is_receive_show">
				<van-button round block type="info" native-type="submit">立即接单</van-button>
			</div>
		</van-form>
	</div>
</template>

<script>
import { Toast } from 'vant';
export default {
	data() {
		return {
			title:'',
			programme: '',
			order_no:'',
			is_show:false,
			is_programme_show:false,
			is_receive_show:false,
			deviceInfo:{
				base_order_no:"",
				device_name:"",
				device_code:"",
				device_dept_name:"",
				status:""
			},
      repairUserInfo:{
        name:'',
        mobile:''
      }
		};
	},
	methods: {
		navBack() {
			this.$router.go(-1)
		},
		async onSubmit() {
			let programme = this.programme
			if(programme == '')
			{
				this.$toast.fail('请填写维修内容');
			}
			
			//调用竣工接口
			const {data:sfr_res} = await this.$http.post('quickOrderCompleted',{order_no:this.order_no,programme:programme})
			if(sfr_res.code == 200)
			{	
				this.is_show = false
				this.deviceInfo.status = '已竣工'
				this.$toast.success('竣工成功');
			}
			else
			{
				this.$toast.fail(sfr_res.message);
			}
		},
		//立即接单
		async quickOrderReceive() {
			console.log(this.order_no)
			const {data:res} = await this.$http.post('quickOrderReceive',{order_no:this.order_no,code:this.deviceInfo.device_code})
			if(res.code == '200')
			{
				Toast.success('接单成功');
				this.is_show = true
				this.is_programme_show = true
				this.is_receive_show = false
				this.deviceInfo.status = '维修中'
				this.title = '维修中'
			}
			else
			{
				Toast.fail('接单失败');
				this.err_info = res.message
				this.show = true
			}
		}
	},
	async created() {
		this.order_no = this.$route.query.order_no
		const {data:res} = await this.$http.get("getQuickRepairByCode?order_no="+this.order_no)
		if(res.code != 200)
		{
			this.$toast.fail(res.message);
		}
		this.deviceInfo = res.results
		this.programme = res.results.programme
    this.repairUserInfo={name:res.results.repair_user_info[0],mobile:res.results.repair_user_info[1]}
		if(this.deviceInfo.status == 'wait')
		{
			this.is_show = false
			this.is_programme_show = false
			this.is_receive_show = true
			this.deviceInfo.status = '待接单'
			this.title = '待接单'
		}
		else if(this.deviceInfo.status == 'under-repair')
		{
			this.is_show = true
			this.is_programme_show = true
			this.is_receive_show = false
			this.deviceInfo.status = '维修中'
			this.title = '维修中'
		}
		else if(this.deviceInfo.status == 'completed')
		{
			this.is_show = false
			this.is_programme_show = true
			this.is_receive_show = false
			this.deviceInfo.status = '已竣工'
			this.title = '已竣工'
		}
	},
}
</script>

<style>
.receive_detail{
	margin-top: 0.2rem;
}
.receive_content{
	margin-top: 0.2rem;
}
</style>
