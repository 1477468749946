<template>
	<div class="index-container">
		<van-nav-bar
			title="快速接单"
			left-text="返回"
			left-arrow
			@click-left="navBack"
		/>
		<div class="empty" v-if="show">
			<van-empty
			class="custom-image"
			:image="require('../assets/image/device/empty.png')"
			:description="err_info"
			/>
		</div>
	</div>
</template>

<script>
import {getDeviceCode} from "../utils/device";
import { Toast } from 'vant';
export default {
	data() {
		return {
			device_info:'',
			show:false,
			err_info:''
		};
	},
	methods: {
		navBack() {
			this.$router.go(-1)
		},
		scanOrderQr() {
			let that=this
			window.em.scanQRCode({
				scanType: ["qrCode", "barCode"],
				needResult:1,
				async success(res1) {
					let code=getDeviceCode(res1.resultStr)
					//直接接单
					const {data:res} = await that.$http.post('quickOrderReceive',{order_no:that.$route.query.order_no,code:code})
					if(res.code == '200')
					{
						Toast.success('接单成功');
						setTimeout(() => {
							//成功后跳转详情页
							that.$router.push('/quick_order_detail?order_no='+that.$route.query.order_no)
						}, 1000);
					}
					else
					{
						Toast.fail('接单失败');
						that.err_info = res.message
						that.show = true
					}
				}
					
			})
		}
	},
	created()
	{
		this.scanOrderQr()
	}
}
</script>

<style>
.receive_detail{
	margin-top: 0.2rem;
}
.receive_content{
	margin-top: 0.2rem;
}
</style>
