<template>
	<div class="index-container">
		<van-nav-bar
			title="快速报修单列表"
			left-text="返回"
			left-arrow
			@click-left="navBack"
		/>
		<van-tabs v-model="active" @click="changeStatus">
			<van-tab :title="title[index]" v-for="(item,index) in title" :key="item" class="wait">
				<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
					<van-list
						v-model="loading"
						:finished="finished"
						finish-text="没有更多了"
						loading-text="加载中"
						@load="onLoad"
					>
					
					<van-cell 
						v-for="item in list" 
						:key="item.order_no" 
						center
						:label="item.time_repair"
						:to="'quick_order_detail?order_no=' + item.order_no"
          >
            <template #title>
              <div style="display: flex;flex-direction: row;align-items: center">
              <span class="custom-title">{{item.device_dept_name}}  {{item.device_name}}</span>
              <van-icon name="qr" size="20px" style="margin-left: auto" @click.stop="showRepairOrderQrCode(item.order_no)"/>
              </div>
            </template>

          </van-cell>
					</van-list>
				</van-pull-refresh>
			</van-tab>
		</van-tabs>
		<div class="empty" v-if="has_list_show">
			<van-empty
			class="custom-image"
			:image="require('../assets/image/device/empty.png')"
			description="暂无维修单"
			/>
		</div>
    <van-dialog v-model="qrCodeShow" title="维修单二维码" >
      <div class="qrcode" ref="qrCodeUrl"></div>
    </van-dialog>
	</div>
</template>

<script>
import QRCode from "qrcodejs2";

export default {
	data() {
		return {
      qrCodeShow:false,
			active: 0,
			list: [],
			loading: false,
			finished: false,
			refreshing: false,
			title: [
				"待接单",
				"维修中",
				"已竣工"
			],
			page_no:1,
			page_size:10,
			status:'wait',
			has_list_show:false
		};
	},
	// mounted() {
	// 	this.getList();  
	// }, 
	methods: {
    showRepairOrderQrCode(text){
      this.qrCodeShow=true
      let that=this
      this.$nextTick(function (){
        this.$refs.qrCodeUrl.innerHTML = ""
        new QRCode(that.$refs.qrCodeUrl,{
          text:text,
          width:"250",
          height:"250",
          colorDark:"#000000",
          colorLight:"#FFFFFF"
        })
      })
    },
		navBack() {
			this.$router.go(-1)
		},
		// created() {
		// 	this.getList()
		// },
		onLoad() {
			setTimeout(() => {
				this.getList();
			}, 500);
		},
		onRefresh() {
			setTimeout(() => {
				this.page_no = 1;
				this.list = [];
				this.getList()
				this.refreshing = false
			}, 500);
		},
		getList(){
			//获取列表
			this.$http.get('getQuickRepairList?page_no=' + this.page_no + '&page_size=' + this.page_size + '&status=' + this.status).then(repair_list=>{
				if(repair_list.data.code == '200')
				{
					console.log(repair_list)
					//判断是否有数据
					if(repair_list.data.paging.total_records == 0)
					{
						this.has_list_show = true
					}
					else
					{
						this.has_list_show = false
						// 如果是第一次进入页面page==1 直接赋值
						if(this.page_no == 1)
						{
							this.list = repair_list.data.results
						}
						else
						{
							// 如果不是则在后面追加数据,forEach()方法
							repair_list.data.results.forEach(item => {
								this.list.push(item)
							});
						}
						
						// 当还有数据是page++        
						if ( repair_list.data.results.length > 0 && repair_list.data.results.length == this.page_size) {
							this.loading = false
						} else {
							// 如果没有数据加载完毕
							this.finished = true
							this.loading = true
						}
						this.page_no++;
					}
				}
				else
				{
					this.list = [];
					this.finished = true
					this.loading = true
				}
			})
			
		},
		changeStatus(index, title)
		{
			if(title == '待接单')
			{
				this.status = 'wait'
			}
			else if(title == '维修中')
			{
				this.status = 'under-repair'
			}
			else if(title == '已竣工')
			{
				this.status = 'completed'
			}
			this.page_no = 1;
			this.list = [];
			this.getList();
		}
	}	
}
</script>

<style>
.wait{
	margin-top: 0.2rem;
}
.qrcode {
  width: 100%;
  margin-top: 10px;
  /*padding: 20px;*/
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
