import Vue from "vue";
import {
	CountDown,
	NoticeBar,
	Checkbox,
	CheckboxGroup,
	Icon,
	Empty,
	Col,
	Row,
	Form,
	Field,
	Button, Image as VanImage,
	Toast,
	Grid,
	GridItem,
	Cell,
	CellGroup,
	Tab,
	Tabs,
	ImagePreview,
	Uploader,
	Popup,
	Picker,
	Dialog,
	NavBar,
	PullRefresh,
	List,
	SwitchCell,
	Switch,
	Tag,
	Radio,
	RadioGroup,
} from "vant";

Vue.use(Col);
Vue.use(Row);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(VanImage);
Vue.use(Toast);
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(NoticeBar)
Vue.use(CountDown)
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Tab);
Vue.use(Tabs)
Vue.use(ImagePreview )
Vue.use(Uploader)
Vue.use(Popup)
Vue.use(Picker)
Vue.use(Dialog)
Vue.use(NavBar)
Vue.use(Empty)
Vue.use(Icon)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(PullRefresh)
Vue.use(List)
Vue.use(Field)
Vue.use(SwitchCell)
Vue.use(Switch)
Vue.use(Tag)
Vue.use(Radio)
Vue.use(RadioGroup)
