<template>
  <div class="index-container">
    <van-nav-bar
    >
      <div slot="left" class="nav-title">MES设备报修系统V{{ version }}{{ environment }}</div>
      <div slot="right" class="nav-right">
        <div class="welcome">欢迎您:{{ name }}</div>
      </div>
    </van-nav-bar>
    <div class="logout"><span @click="logOut">退出登录</span></div>
    <div class="item-container">
      <div class="item" @click="deviceCenter">
        <van-icon name="graphic"/>
        <div class="item-descrip">设备中心</div>
      </div>
      <!-- <div class="item" @click="versionRecord">
        <van-icon name="todo-list-o" />
        <div class="item-descrip">版本记录</div>
      </div> -->
      <div class="item" @click="quickRepair" v-if="quick_repair_is_show">
        <van-icon name="scan"/>
        <div class="item-descrip">快速报修</div>
      </div>
      <div class="item" @click="quickRepairList" v-if="quick_repair_list_is_show">
        <van-icon name="bars"/>
        <div class="item-descrip">快速报修列表</div>
      </div>
    </div>

    <van-dialog v-model="quickRepairShow" title="是否快速报修" show-cancel-button @cancel="cancelFastRepair" @confirm="createFastRepair">
      <van-field
          v-model="description"
          rows="1"
          autosize
          label="故障描述"
          type="textarea"
          placeholder="请填写故障描述,非必填"
      />
    </van-dialog>
  </div>


</template>

<script>
// import {Dialog} from 'vant';
import {Toast} from 'vant';
import {getDeviceCode} from "../utils/device";
import config from '../../package.json'

export default {
  data() {
    return {
      description: "",//快速报修故障描述
      quickRepairShow: false,
      name: '',
      code: '',
      device_info: '',
      show: false,
      device_message: '',
      quick_repair_is_show: false,
      quick_repair_list_is_show: false,
      version: config.version
    };
  },
  methods: {
    //退出登录
    logOut() {
      window.localStorage.clear()
      this.$router.push('/login')
    },
    //设备中心
    deviceCenter() {
      this.$router.push('/device_center')
    },
    // versionRecord(){
    //   this.$toast('开发中的功能')
    // },

    //展示快速报修弹框
    quickRepair() {
      let that = this
      window.em.scanQRCode({
        scanType: ["qrCode", "barCode"],
        needResult: 1,
        async success(res1) {
          let code = getDeviceCode(res1.resultStr)
          that.code = code
          const {data: res} = await that.$http.get('getDeviceInfo?code=' + code)
          if (res.code === 1) {
            that.device_info = res.data.info
            //提示是否报修
            that.quickRepairShow = true
          } else {
            Toast.fail(res.msg);
          }
        }
      });
    },
    cancelFastRepair(){
      this.description=''
      this.quickRepairShow=false
    },
    //发起快速报修
    async createFastRepair() {
      const {data: sfr_res} = await this.$http.post('submitFastRepairData', {
        code: this.code,
        device: this.device_info,
        description:this.description
      })
      if (sfr_res.code == 200) {
        this.description=''
        Toast.success('报修成功');
        //成功后跳转详情页
        this.$router.push('/quick_order_detail?order_no=' + sfr_res.results.order_no)
      } else {
        Toast.fail(sfr_res.message);
      }
    },

    quickRepairList() {
      this.$router.push('/quick_repair_list')
    }
  },
  created() {
    let name = window.localStorage.getItem('name')
    this.name = name
    this.environment = process.env.NODE_ENV === 'production' ? '' : (process.env.NODE_ENV === 'test' ? '【测试版】' : '【开发版】');
    //is_allow_quick_repair 是否允许快速报修 0-不允许 1-允许
    let is_allow_quick_repair = window.localStorage.getItem('is_allow_quick_repair')
    if (is_allow_quick_repair == 1) {
      //role=0 既不是报修专组 也不是维修的 role =1  报修专组   role=2 维修专组
      if (window.localStorage.getItem('role') == 0) {
        this.quick_repair_is_show = false
        this.quick_repair_list_is_show = false
      } else if (window.localStorage.getItem('role') == 1) {
        this.quick_repair_is_show = true
        this.quick_repair_list_is_show = true
      } else if (window.localStorage.getItem('role') == 2) {
        this.quick_repair_is_show = false
        this.quick_repair_list_is_show = true
      }
    }
  }
}
</script>

<style scoped>
.index-container {
  height: 100%;
  width: 100%;
}

.item {
  width: 30vw;
  height: 35vw;
  margin-left: 2.5vw;
  margin-top: 2.5vw;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 2vw;
}

.item-descrip {
  font-size: 14px;
  margin-top: 10px;
}

.item-container {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-content: start;
  box-sizing: border-box;
}

.logout {
  position: fixed;
  bottom: 20px;
  text-align: center;
  font-size: 14px;
  color: #999999;
  width: 100%;
}

.nav-title {
  color: #323233;
  font-weight: 500;
  font-size: 0.42667rem;
}

.welcome {
  font-size: 12px;
  color: #999999;
}

.nav-right {
  display: flex;
  flex-direction: column;
}
</style>