<template>
  <div class="index-container">
    <van-nav-bar
        title="设备报修"
        left-text="返回"
        left-arrow
        @click-left="navBack"
    />
    <div class="device-name">{{ device_name }}</div>
    <van-form @submit="onSubmit">
      <van-cell-group>
        <van-field
            v-model="name"
            label="报修人"
            disabled
        />
        <van-field
            v-model="mobile"
            label="联系方式"
            disabled
        />
       <van-field
        readonly
        clickable
        name="picker"
        :value="faultFirstContent"
        label="故障类型"
        placeholder="请选择"
        @click="showFirstType = true"
        />
        <van-popup v-model="showFirstType" position="bottom">
          <van-picker
              show-toolbar
              :columns="faultFirstData"
              :value-key="'name'"
              @confirm="confirmFirstType"
              @cancel="showFirstType = false"
          />
        </van-popup>


        <van-field
            readonly
            clickable
            name="picker"
            :value="faultSecondContent"
            label="故障明细"
            placeholder="请选择"
            @click="showSecondType = true"
            v-if="faultSecondData.length>0"
        />

        <van-field
            v-model="repairDuration"
            label="维修时长(分)"
            disabled
        />

        <van-popup v-model="showSecondType" position="bottom">
          <van-picker
              show-toolbar
              :columns="faultSecondData"
              :value-key="'name'"
              @confirm="confirmSecondType"
              @cancel="showSecondType = false"
          />
        </van-popup>
        <van-field name="radio" label="是否因设备异常造成产品损坏">
          <template #input>
            <van-radio-group v-model="formData.is_production_confirm_trash" direction="horizontal">
              <van-radio name="1">是</van-radio>
              <van-radio name="2">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field name="uploader" label="">
          <template #label>
            <div>故障图片/<span style="font-weight: bold;color: red">产品损坏图片</span></div>
          </template>
          <template #input>
            <van-uploader v-model="uploadFiles"
                          :accept="'image/png, image/jpeg,image/jpg,image/tmp'" :multiple="true"/>
          </template>
        </van-field>
        <van-field
            v-model="formData.repair.describe"
            rows="4"
            autosize
            label="故障描述"
            type="textarea"
            placeholder="故障描述"
            maxlength="600"
        />
        <van-field
            v-model="repairUserStr"
            rows="4"
            autosize
            label="维修人员"
            type="textarea"
            placeholder="暂无需要通知的维修人员"
            maxlength="600"
            readonly
        />
        <van-field
            v-model="copyUserStr"
            rows="4"
            autosize
            label="抄送人员"
            type="textarea"
            placeholder="暂无需要抄送的人员"
            maxlength="600"
            readonly
        />
      </van-cell-group>

      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>

</template>

<script>
export default {
  data() {
    return {
      name: "",
      mobile: "",
      repairUserStr:'',
      copyUserStr:'',
      faultFirstContent:"",
      faultSecondContent:"",
      showFirstType:false,
      showSecondType:false,
      faultFirstData:[],
      faultMapData:{},
      faultSecondData:[],
      uploadFiles: [],

      repairDuration:0,
      formData: {
        is_production_confirm_trash:'',//生产部门选择的是否因为设备异常造成产品损坏
        "device": {
          "id": '',
          "code": "",
          "name": "",
          "department": {
            "id": "",
            "name": ""
          }
        },
        "repair": {
          "type":{
            "id":"",
            "code":"",
            "name":"",
            "sub":{

            }
          },
          "imgs": [],
          "describe": ""
        }
      }
    };
  },
  methods: {
    navBack() {
      this.$router.go(-1)
    },
    repair() {

    },
    onSubmit(){
      if (this.formData.is_production_confirm_trash===''){
        return this.$toast.fail('请选择是否损坏产品');
      }
      if(this.repairUserStr===''){
        return this.$toast.fail('暂无当前班次对应的维修人员,请联系设备部进行设置');
      }
      if (this.faultFirstContent===''){
        return this.$toast.fail('请选择故障分类');
      }
      if(this.faultSecondData.length>0&&this.faultSecondData===''){
        return this.$toast.fail('请选择故障明细');
      }
      if (this.uploadFiles.length===0){
        return this.$toast.fail('请上传故障或者产品损坏图片');
      }
      console.log(this.uploadFiles);
      if (this.uploadFiles.length>0){
        this.$toast.loading({message:'上传图片中'})
      }
      this.uploadRepairImgs(this.uploadFiles,0)
    },

    async uploadRepairImgs(uploadFiles, i) {
      if (uploadFiles.length === i) {
        //上传图片完毕
        this.$toast.clear()
        setTimeout(()=>{
          this.$toast.loading({message: '提交表单数据'})
          this.saveFormData()
        },1000)
        return;
      }
      let data = new FormData();
      data.append('DingDingUpFile', uploadFiles[i].file)
      const {data: res} = await this.$http.post('uploadDeviceRepairFile', data)
      if (res.code === 1) {
        this.formData.repair.imgs.push(res.data.url)
      } else {
        console.log('上传失败,忽略')
      }
      await this.uploadRepairImgs(uploadFiles, i + 1)
    },
    async saveFormData() {
      const {data:res}=await this.$http.post('submitRepairData',this.formData)
      if (res.code===1){
        this.$toast.success(res.msg);
        await this.$http.post('sendNoticeDeviceOrder',{order_no:res.data.order_no,type:1})
        this.$router.go(-2)
      } else {
        this.$toast.fail(res.msg)
      }
    },

    confirmFirstType(e){
      this.faultSecondData=this.faultMapData[e.id]
      this.faultFirstContent=e.name
      this.faultSecondContent=''
      this.formData.repair.type.sub={}
      this.formData.repair.type.id=e.id
      this.formData.repair.type.code=e.code
      this.formData.repair.type.name=e.name
      this.showFirstType=false
    },
    confirmSecondType(e){
      if (e!='undefined'){
        console.log(e)
        this.faultSecondContent=e.name
        this.formData.repair.type.sub.id=e.id
        this.formData.repair.type.sub.code=e.code
        this.formData.repair.type.sub.name=e.name
        this.showSecondType=false
        this.repairDuration=e.repair_duration
      }
    },
    async getDeviceFaultType(code){
      const {data:res}=await this.$http.get('getDeviceFaultType?code='+code)
      if (res.code===1){
        let FaultType=res.data.result.FaultType
        //组装数据
        FaultType.forEach(element=>{
            if (element.parent_id===0){
              this.faultFirstData.push(element);
              this.faultMapData[element.id]=[]
            }
        })
        FaultType.forEach(element=>{
          if (element.parent_id!==0){
            this.faultFirstData.forEach(ele=>{
              if (element.parent_id===ele.id){
               this.faultMapData[ele.id].push(element)
              }
            })
          }
        })
        console.log(this.faultFirstData)
        console.log(this.faultMapData,999)
      }
    },
    async getNoticeOaUser(code){
      const {data:res}=await this.$http.get('getNoticeOaUsers?code='+code)
      if (res.code===1){
        let repairMembers=res.data.repairMembers
        let copyMembers=res.data.copyMembers
        let repairMembeArr=[]
        repairMembers.forEach(e=>{
          repairMembeArr.push(e.name)
        })
        let copyMembeArr=[]
        copyMembers.forEach(e=>{
          copyMembeArr.push(e.name)
        })
        this.repairUserStr=repairMembeArr.join(',')
        this.copyUserStr=copyMembeArr.join(',')
      }
    }
  },
  computed:{

  },
  created() {
    let device_id=this.$route.query.device_id
    let device_code = this.$route.query.device_code
    this.device_name=this.$route.query.device_name

    this.formData.device.id=device_id
    this.formData.device.code=device_code
    this.formData.device.name=this.device_name

    this.formData.device.department.id=this.$route.query.department_id
    this.formData.device.department.name=this.$route.query.department_name

    this.name = window.localStorage.getItem('name')
    this.mobile=window.localStorage.getItem('mobile')
    this.getDeviceFaultType(device_code)
    this.getNoticeOaUser(device_code)
  }
}
</script>
<style>
.device-name {
  height: 40px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  line-height: 40px;
}
</style>