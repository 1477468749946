<template>
  <div
      :class="{ 'login-container': !isLogin, 'has-login': isLogin }"
  >
    <template v-if="!isLogin">
      <div class="modal"></div>
      <div class="login—box">
        <van-image
            width="100"
            height="100"
            src="https://i.loli.net/2021/10/12/7mp1TqVbySAItBu.jpg"
            class="logo"
        />
        <div class="title">MES设备报修系统V{{version}}{{environment}}</div>
        <van-form @submit="onSubmit">
          <van-field
              v-model="username"
              name="用户名"
              label="用户名"
              placeholder="用户名"
              :rules="[{ required: true, message: '请填写用户名' }]"
          ></van-field>
          <van-field
              v-model="password"
              type="password"
              name="密码"
              label="密码"
              placeholder="密码"
              :rules="[{ required: true, message: '请填写密码' }]"
          ></van-field>
          <div class="submit-button">
            <van-button round block type="info" native-type="submit"
            >登录
            </van-button
            >
          </div>
        </van-form>
        <div style="position: fixed;bottom: 10px;text-align: center;font-size: 14px;color: #FFFFFF">@2022 Milily MES</div>
      </div>
    </template>
    <template v-if="isLogin">
      <div style="display:flex;align-items: center;justify-content: center;height: 100%">
        <div style="font-size: 20px;color: #999999">已登录,<van-count-down :time="time" format="ss" style="display: inline-block" />秒内返回首页</div>
      </div>
    </template>
  </div>
</template>


<script>
import config from '../../package.json'
export default {
  data() {
    return {
      isLogin: false,
      username: "",
      password: "",
      time:3000,
      version:config.version
    };
  },
  methods: {
    async onSubmit() {
      this.$toast.loading({message:'登录中...'})
      const {data: res} = await this.$http.post("login",
          {
            username:this.username,
            password:this.password
          });
      console.log('登录调用结束')
      if (res.code === 1) {
        let that = this;
        let role = res.data.role;
        window.localStorage.setItem("role", role);
        window.localStorage.setItem("name", res.data.name);
        window.localStorage.setItem("mobile", res.data.mobile);
        window.localStorage.setItem("token", res.data.token);
        window.localStorage.setItem("is_allow_quick_repair", res.data.is_allow_quick_repair);
        this.$toast.success({
          message: '登录成功',
          onOpened: () => {
            console.log('打开')
          },
          onClose() {
            console.log('关闭')
            that.$router.push("/index");
          },
        });
      } else {
        this.$toast.fail(res.msg);
      }
    },
  },
  async created() {
    let token = window.localStorage.getItem("token");
    this.environment=process.env.NODE_ENV==='production'?'':(process.env.NODE_ENV==='test'?'【测试版】':'【开发版】');
    this.$nextTick(function () {
      this.isLogin = !!token;
      if (this.isLogin) {
        setTimeout(() => {
          this.$router.replace('/index')
        }, 3000)
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.xrzs{
  color: #FFF;
  font-size: 16px;
  margin-top: 20px;
  text-align: center;
  position: fixed;

}
.login-container {
  background-image: url("../assets/image/bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  height: 100%;
  width: 100%;
  z-index: -2;
  border-top: 0.00625rem solid transparent;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.has-login {
  height: 100%;
  width: 100%;
}

.logo {
  margin-bottom: 20px;
}

.title {
  margin-bottom: 30px;
  font-size: 14px;
  color: #ffffff;
  z-index: 999;
}

.login—box {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.modal {
  background: #000000;
  opacity: 0.5;
  /* z-index: -1; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.submit-button {
  margin-top: 20px;
}
</style>
