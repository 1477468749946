import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login";
import Index from "../views/Index";
import DeviceCenter from "../views/DeviceCenter";
import RepairList from "../views/RepairList";
import Detail from "../views/Detail";
import History from "../views/History";
import DeviceInfo from "../views/DeviceInfo";
import Repair from "../views/Repair";
import QuickOrderReceive from "../views/QuickOrderReceive";
import QuickRepairList from "../views/QuickRepairList";
import QuickOrderDetail from "../views/QuickOrderDetail";


Vue.use(VueRouter);
const routes = [
  { path: "/", redirect: "/index" },
  { path: "/index", component: Index },
  { path: "/device_center", component: DeviceCenter },
  { path: "/repair_list", component: RepairList ,name:"repair_list"},
  { path: "/detail", component: Detail },
  { path: "/history", component: History },
  {path: "/device_info",component: DeviceInfo},
  {path: "/repair",component: Repair},
  {path: "/quick_order_receive",component: QuickOrderReceive},
  {path: "/quick_repair_list",component: QuickRepairList},
  {path: "/quick_order_detail",component: QuickOrderDetail},
  {
    path: "/login",
    component: Login,
  }
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path === "/login") return next();
  const token = window.localStorage.getItem('token')
  if (token == null) {
    return next("/login");
  }
  next();
});

export default router;
