<template>
  <div id="app" v-cloak>
    <!-- 路由视图 -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {

}
</script>

<style>
[v-cloak]{
  display: none;
}	
</style>
