<template>
  <div class="index-container">
    <van-nav-bar
        title="设备信息"
        left-text="返回"
        left-arrow
        @click-left="navBack"
    />
    <van-cell-group>
      <van-cell title="设备名称" :value="deviceInfo.device_name"/>
      <van-cell title="设备编号" :value="deviceInfo.device_code"/>
      <van-cell title="类型" :value="deviceInfo.topList||'暂缺'" :class="{'red-text':deviceInfo.topList==''||deviceInfo.topList==null}"/>
      <van-cell title="功率" :value="deviceInfo.power||'暂缺'" :class="{'red-text':deviceInfo.power==''||deviceInfo.power==null}"/>
      <van-cell title="型号" :value="deviceInfo.device_spec||'暂缺'" :class="{'red-text':deviceInfo.device_spec==''||deviceInfo.device_spec==null}"/>
      <van-cell title="分类" :value="deviceInfo.sign_name||'暂缺'" :class="{'red-text':deviceInfo.sign_name==''||deviceInfo.sign_name==null}"/>
      <van-cell title="使用状态" :value="deviceInfo.status_name||'暂缺'" :class="{'red-text':deviceInfo.status_name==''||deviceInfo.status_name==null}"/>
      <van-cell title="维修班组" :value="deviceInfo.team_name||'暂缺'" :class="{'red-text':deviceInfo.team_name==''||deviceInfo.team_name==null}"/>
      <van-cell title="安置车间" :value="deviceInfo.department_name||'暂缺'" :class="{'red-text':deviceInfo.department_name==''||deviceInfo.department_name==null}"/>
      <van-cell title="安置地点" :value="deviceInfo.address||'暂缺'" :class="{'red-text':deviceInfo.address==''}"/>
      <van-cell title="资产编号" :value="deviceInfo.asset_code||'暂缺'" :class="{'red-text':deviceInfo.asset_code==''||deviceInfo.asset_code==null}"/>
      <van-cell title="原始价值" :value="deviceInfo.initial_price"/>
      <van-cell title="资产净值" :value="deviceInfo.net_price" />
      <van-cell title="验收/入库日期" :value="deviceInfo.purchase_time" />
      <van-cell title="负责人" :value="deviceInfo.employee_name||'暂缺'" :class="{'red-text':deviceInfo.employee_name==''||deviceInfo.employee_name==null}"/>
      <van-cell title="供应商" :value="deviceInfo.supplier_name||'暂缺'" :class="{'red-text':deviceInfo.supplier_name!=''}"/>
      <van-cell title="保修天数" :value="deviceInfo.warranty_days||'暂缺'" :class="{'red-text':deviceInfo.warranty_days!=''}"/>
      <van-cell title="是否过保" :value="deviceInfo.is_guobao||'暂缺'" :class="{'red-text':deviceInfo.is_guobao=='已过保'}"/>
    </van-cell-group>
    <div class="button-group">
      <van-button type="info" :disabled="repairButton" @click="repair" v-if="role==='1'">申请报修</van-button>
      <van-button type="danger" @click="showStatusModal" v-if="role==='2'">状态设定</van-button>
    </div>

    <van-dialog v-model="statusModalShow" title="设定状态" @cancel="cancelSelect" @confirm="setStatus"  show-cancel-button>
        <div class="status-list">
          <div :class=" {'status-item':true,'active':index===currentStatusIndex}" v-for="(item,index) in statusList" :key="item.id" @click="selectStatus(item,index)">{{item.name}}</div>
        </div>
    </van-dialog>

  </div>

</template>

<script>
export default {
  data() {
    return {
      setStatusButton:false,
      repairButton:false,
      deviceInfo: {
        device_name:"",
        device_code:"",
        topList:"",
        power:"",
        device_spec:"",
        sign_name:"",
        status_name:"",
        team_name:"",
        department_id:"",
        department_name:"",
        address:"",
        asset_code:"",
        initial_price:"",
        net_price:"",
        purchase_time:"",
        employee_name:"",
        supplier_name:""
      },
      statusList:[],
      currentStatusIndex:0,
      statusModalShow:false
    };
  },
  methods: {
    navBack(){
      this.$router.go(-1)
    },
    repair(){
      if (!this.repairButton){
        this.repairButton=true
          if (this.role==='2'){
            this.$toast.fail('您暂无报修权限')
          }
          this.$router.push('/repair?device_code='+this.deviceInfo.device_code+"&device_name="+this.deviceInfo.device_name+
          "&device_id="+this.deviceInfo.id+"&department_id="+this.deviceInfo.department_id+"&department_name="+this.deviceInfo.department_name)
      }
    },
    async getDeviceInfo(device_code){
      let that=this
      const {data:res}=await this.$http.get('getDeviceInfo?code='+device_code)
      if (res.code===1){
        console.log(res)
        this.deviceInfo=res.data.info
        this.getDeviceStatus()
      } else {
        that.$toast.fail({
          message:res.msg,
          onClose(){
            that.$router.go(-1)
          }
        })
      }
    },
    //获取设备状态
    async getDeviceStatus(){
      let that=this
      const {data:res}=await this.$http.get('getDeviceOptions?cid=0')
      if (res.code===1){
        this.statusList=res.data.result
        this.statusList.forEach((element,index)=>{
          console.log(element.name)
          if (element.name===that.deviceInfo.status_name){
            this.currentStatusIndex=index
          }
        })
      }
    },
    //显示状态设定框
    showStatusModal(){
      console.log(123)
      this.statusModalShow=true
    },
    //选择状态
    selectStatus(item,index){
      this.currentStatusIndex=index
    },
    cancelSelect(){
      this.statusModalShow=false
      this.statusList.forEach((element,index)=>{
        console.log(element.name)
        if (element.name===this.deviceInfo.status_name){
          this.currentStatusIndex=index
        }
      })
    },
    async setStatus(){
      console.log(this.setStatusButton)
      if (!this.setStatusButton){
        this.setStatusButton=true
        const {data:res}=await this.$http.post('setDeviceStatus',{code:this.deviceInfo.device_code,status_id:this.statusList[this.currentStatusIndex].id})
        if (res.code===1){
          this.$toast.success(res.msg)
          this.deviceInfo.status_name=this.statusList[this.currentStatusIndex].name
        } else {
          this.$toast.fail(res.msg)
          this.statusList.forEach((element,index)=>{
            if (element.name===this.deviceInfo.status_name){
              this.currentStatusIndex=index
            }
          })
        }
        setTimeout(()=>{
          this.setStatusButton=false
        },1000)
      }
    }
  },
  created() {
    let device_code = this.$route.query.device_code
    this.getDeviceInfo(device_code)
    this.role=window.localStorage.getItem('role')
  }
}
</script>
<style>
.custom-image .van-empty__image {
  width: 285px !important;
  height: 150px !important;
}
</style>
<style scoped>
.red-text .van-cell__value{
  color: red;
}
.button-group {
  display: flex;
  padding: 10px 20px;
  flex-direction: row;
  justify-content: space-evenly;
}
.status-list{
  display: flex;
  flex-wrap: wrap;
  /*height: 200px;*/
  padding: 10px;
}
.status-item{
  padding: 10px 15px;
  margin-left: 5px;
  margin-top: 5px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid #999999;
  color: #999999;
}
.active{
  border: 1px solid red;
  color: red;
}
</style>