<template>
  <div class="index-container">
    <van-nav-bar
        title="MES设备经验列表"
        left-text="返回"
        left-arrow
        @click-left="navBack"
    />
    <div class="history-list">
      <div class="history-item" v-for="item in historyRepairList" :key="item.id" @click="detail(item.order_no)">
        <div class="label" ><b>设备名称：</b>{{item.device_name}}({{item.device_code}})</div>
        <div class="label" style="margin-top: 5px"><b>所在车间：</b>{{item.device_dept_name}}</div>
        <div class="label" style="margin-top: 5px"><b>竣工时间：</b>{{item.time_finish}}</div>
      </div>
    </div>

    <div class="empty" v-if="historyRepairList&&historyRepairList.length===0">
      <van-empty
          class="custom-image"
          :image="require('../assets/image/device/empty.png')"
          description="暂无设备经验"
      />
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      historyRepairList:null
    };
  },
  methods: {
    navBack(){
      this.$router.go(-1)
    },
    async getDeviceRepairExp(device_code){
        const {data:res}=await this.$http.get('getRepairOrders?act=exp&device_code='+device_code)
        if (res.code===1){
          console.log(res)
          this.historyRepairList=res.data.result
        }
    },
    detail(order_no){
      this.$router.push('/detail?order_no='+order_no)
    }

  },
  created() {
    let device_code = this.$route.query.device_code
    this.getDeviceRepairExp(device_code)
  }
}
</script>
<style>
.custom-image .van-empty__image {
  width: 285px !important;
  height: 150px !important;
}
</style>
<style scoped>
.index-container {
  height: 100%;
  width: 100%;
}
.empty{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: -46px;
}
.history-list{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}
.history-item{
  width: 90vw;
  background: #FFFFFF;
  border-radius: 0.13333rem;
  padding:10px 10px;
  box-sizing: border-box;
  margin-top: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: start;
  font-size: 14px;
}
</style>