<template>
  <div class="index-container">
    <van-nav-bar
        title="维修列表"
        left-text="返回"
        left-arrow
        @click-left="navBack"
    />
    <van-tabs v-model="active" sticky :title-active-color="titleColor">
      <van-tab :title="title[index]" v-for="(item,index) in status" :key="item">
        <div class="repair-container" v-if="orderList!=null">
          <div class="repair-item" v-for="(order) in orderList[status[index]]" :key="order.order_no"
               @click="repairDetail(order.order_no)">
            <div class="label">
              <div style="display: flex;justify-content: flex-start;align-items: center;flex-direction: row">
                <span><b>设备名称：</b>{{ order.device_name }}({{ order.device_code }})</span>
                <van-icon name="qr" size="20px" style="margin-left: auto"
                          @click.stop="showRepairOrderQrCode(order.order_no)"/>
              </div>
            </div>
            <div class="label" style="margin-top: 5px"><b>所在车间：</b>{{ order.device_dept_name }}</div>
            <div class="label" style="margin-top: 5px"><b>报修时间：</b>{{ order.time_repair }}</div>
            <div class="label" style="margin-top: 5px" v-if="order.status==='finish'">
              <b>维修完成时间：</b>{{ order.accept_finish_time }}
            </div>
            <div class="label" style="margin-top: 5px" v-if="order.status==='completed'">
              <b>竣工时间：</b>{{ order.time_finish }}
            </div>
            <div class="label van-row--flex" style="margin-top: 5px">
              <div><b>工单状态：</b><span :style="{'color':dict[order.status].color}">{{ dict[order.status].name }}</span>
              </div>
            </div>
            <div class="label" style="margin-top: 5px">
              <b>联系状态：</b><span style='color: #0e1010' v-if="order.is_contact==1">已联系</span>
              <span style='color: #f80e0e' v-if="order.is_contact==2">未联系</span>
            </div>
            <div v-if="order.push_oa_status&&order.push_oa_status===1&&order.status==='under-repair'"
                 class="push-to-oa-ing">
              <van-icon name="info-o"/>
              <span style="margin-left: 2px">oa设备大修申请中,等待oa处理中...</span>
            </div>
            <div v-if="order.push_oa_status&&order.push_oa_status===2&&order.status==='under-repair'"
                 class="push-to-oa-fail">
              <van-icon name="warning-o"/>
              <span style="margin-left: 2px">oa驳回大修申请,请继续执行接下来的流程。</span>
            </div>
            <div v-if="order.push_oa_status&&order.push_oa_status===3&&order.status==='under-repair'"
                 class="push-to-oa-success">
              <van-icon name="passed"/>
              <span style="margin-left: 2px">oa处理成功,请继续执行接下来的流程。</span>
            </div>

            <div class="button-group" v-if="order.status==='wait' && order.is_contact===2">
              <van-button style="margin-right: 5px"  size="small" type="info" @click.stop="showContactMemberList(order.order_no)">
                查看联系人
              </van-button>
              <van-button style="margin-right: 5px"  size="small" type="warning" @click.stop="confirmIsContact(order.order_no)">
                确认联系
              </van-button>
            </div>

            <div class="button-group" v-if="order.push_oa_status!==1">
              <van-button style="margin-right: 5px" :type="button.type" size="small"
                          v-for="button in buttonGroup[role][order.status]"
                          :key="button.id"
                          @click.stop="operate(button.id,order.order_no,order.device_code,order.push_oa_status,order.estimated_price,order.repair_people_num,order.is_guobao,order.time_repair)">
                {{ button.name }}
              </van-button>
            </div>

          </div>
        </div>
      </van-tab>
    </van-tabs>
    <!--        没有-->
    <div class="empty" v-if="orderList==null||(orderList[status[active]]&&orderList[status[active]].length===0)">
      <van-empty
          class="custom-image"
          :image="require('../assets/image/device/empty.png')"
          description="暂无维修单"
      />
    </div>
    <van-dialog v-model="cancelOrderDialogShow" title="撤单" show-cancel-button @confirm="cancelOrder"
                @cancel="hideCancelOrderDialog">
      <van-field
          v-model="cancelReason"
          rows="4"
          autosize
          label="撤单原因"
          type="textarea"
          placeholder="请填写撤单原因"
      />
    </van-dialog>

    <van-dialog v-model="completeOrderDialogShow" title="竣工" show-cancel-button @confirm="completedOrder"
                @cancel="hideCompleteModal">
      <van-cell center>
        <template #title>
          <span class="custom-title">强制校验</span>
          <van-tag type="primary">没有班组的可关闭</van-tag>
        </template>
        <template #right-icon>
          <van-switch v-model="validateGroupChecked" size="24"/>
        </template>
      </van-cell>
      <van-cell-group>
        <van-field
            readonly
            clickable
            label="班组"
            :value="selectWorkGroupName"
            placeholder="选择班组"
            @click="workGroupSelectShow = true"
        />
        <van-popup v-model="workGroupSelectShow" round position="bottom">
          <van-picker
              show-toolbar
              :columns="workGroupList"
              :value-key="'group_name'"
              @cancel="workGroupSelectShow = false"
              @confirm="confirmSelectWorkGroup"
          />
        </van-popup>
        <div style="padding: 0.26667rem 0.42667rem;display: flex">
          <div style="font-size: 0.373rem;color: #646566;width: 1.5rem">人员</div>
          <div style="box-sizing: border-box;display: flex;flex-wrap: wrap;">
            <div class="employee-item" v-for="(item,index) in workGroupEmployeeList" :key="item.id">
              {{ item.name + item.card_id }}
              <van-icon name="cross" @click="removeEmployee(index)"/>
            </div>
          </div>
        </div>
        <div style="padding-left: 10px;box-sizing: border-box;">
          <van-button type="info" size="mini" @click="resetEmployee">重置人员</van-button>
          <van-button type="primary" size="mini" @click="addEmployee">新增</van-button>
          <van-field v-model="card_id" label="输入工号"/>
        </div>
        <van-field v-model="workGroupEmployeeList.length" disabled label="延误人数"/>
        <van-field v-model="affectTime" type="number" label="影响时长(min)"/>

      </van-cell-group>
      <van-checkbox-group v-model="checkboxGroup" direction="horizontal" style="padding: 5px 10px">
        <van-checkbox :name="item" shape="square" v-for="item in evaluateList" :key="item"
                      style="margin-left: 5px;margin-top: 5px">{{ item }}
        </van-checkbox>
      </van-checkbox-group>
    </van-dialog>


    <van-dialog v-model="acceptOrderDialogShow" title="接单" show-cancel-button @confirm="acceptOrderReal">
      <van-field  v-model="estimated_price" label="预估金额" placeholder="请填写预估金额" type="number"/>
<!--      <van-field-->
<!--          readonly-->
<!--          clickable-->
<!--          name="picker"-->
<!--          :value="repairPeopleNum"-->
<!--          label="维修参与人数"-->
<!--          placeholder="点击选择维修参与人数"-->
<!--          @click="showRepairPeopleNumPicker = true"-->
<!--      />-->
      <van-popup v-model="showRepairPeopleNumPicker" position="bottom">
        <van-picker
            show-toolbar
            :columns="repairPeopleNumList"
            @confirm="selectRepairPeopleNum"
            @cancel="showRepairPeopleNumPicker = false"
        />
      </van-popup>
      <van-switch-cell v-model="lockGuaPai" title="请进行挂牌上锁"/>
    </van-dialog>

    <van-dialog v-model="qrCodeShow" title="维修单二维码">
      <div class="qrcode" ref="qrCodeUrl"></div>
    </van-dialog>


    <van-dialog v-model="contactMemberShow" title="联系人员">
      <van-list
      >
        <van-cell
            v-for="item in contactMemberList"
            :key="item.name"
            :title="item.name"
            :value="item.mobile"
        />
      </van-list>
    </van-dialog>

  </div>
</template>

<script>
import {getDeviceCode} from "../utils/device";
import QRCode from 'qrcodejs2'

export default {
  data() {
    return {
      validateGroupChecked: true,
      getFlag: 1,//1.从mes获取班组人员  2.从轻量mes  新增人员始终从mes
      affectTime: '',//影响时长
      card_id: '',
      workGroupEmployeeList: [],
      selectWorkGroupName: '',
      workGroupSelectShow: false,
      workGroupList: [],//当前登录人员所在车间下的班组
      qrCodeShow: false,
      evaluateList: [],
      checkboxGroup: [],
      cancelOrderDialogShow: false,
      completeOrderDialogShow: false,
      acceptOrderDialogShow: false,
      cancelOrderNo: '',
      cancelReason: "",
      completeOrder: '',
      evaluateStr: '',
      role: '1',//角色
      title: [
        "待接单",
        "维修中",
        "维修完成",
        "已撤单",
        "已完成"
      ],
      status: [
        "wait",
        "under-repair",
        "finish",
        "cancel",
        "completed"
      ],
      dict: {
        'wait': {"name": "等待接单中", "color": "#FF6600"},
        'under-repair': {'name': "正在维修中", 'color': "#FF0033"},
        'cancel': {'name': "已撤单", 'color': "#FF0000"},
        'completed': {'name': "已完成", "color": "#006600"},
        'finish': {'name': '维修完成', 'color': "#F57527"}
      },
      buttonGroup: {
        "1": {
          "wait": [{id: 5, name: "催单", type: 'primary'}, {id: 6, name: '撤单', type: 'danger'},
           ],
          "under-repair": [],
          "finish": [{id: 4, name: '竣工', type: 'primary'}],
          "cancel": [],
          "completed": [{id: 3, name: '历史', type: 'info'}]
        },
        "2": {
          "wait": [{id: 1, name: "接单", type: 'primary'}, {id: 3, name: '历史', type: 'info'}],
          "under-repair": [{id: 2, name: "维修", type: 'warning'}, {id: 3, name: '历史', type: 'info'}],
          "finish": [{id: 3, name: '历史', type: 'info'}],
          "cancel": [],
          "completed": [{id: 3, name: '历史', type: 'info'}]
        }
      },
      titleColor: '#ee0a24',
      active: 0,
      orderList: null,

      estimated_price: 0,//预估金额
      lockGuaPai: false,//挂牌上锁
      showRepairPeopleNumPicker: false,// 选择维修人数下拉框
      repairPeopleNum: 1,
      repairPeopleNumList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      currentIsGuobao: '',

      contactMemberShow:false,//联系人员
      contactMemberList: [],
    };
  },

  methods: {

    async showContactMemberList(order_no){
      this.$toast.loading({message:'获取联系人...'})
      const {data:res} = await  this.$http.get('getContactMemberList?order_no=' + order_no)
      if(res.code===1){
        this.contactMemberList=res.data.members;
        if(this.contactMemberList.length===0){
          return this.$toast.fail('暂无联系人员,请联系设备组')
        }
        this.contactMemberShow=true
      } else {
        this.$toast.fail(res.msg)
      }
    },


    showRepairOrderQrCode(text) {
      this.qrCodeShow = true
      let that = this
      this.$nextTick(function () {
        this.$refs.qrCodeUrl.innerHTML = ""
        new QRCode(that.$refs.qrCodeUrl, {
          text: text,
          width: "250",
          height: "250",
          colorDark: "#000000",
          colorLight: "#FFFFFF"
        })
      })
    },
    //选择维修人数
    selectRepairPeopleNum(e) {
      console.log(e)
      this.repairPeopleNum = e
      this.showRepairPeopleNumPicker = false
    },
    hideCompleteModal() {
      console.log(123)
      this.workGroupEmployeeList = []
      this.affectTime = ''
    },

    navBack() {
      window.localStorage.setItem('active', 0)
      this.$router.go(-1)
    },
    async getRepairList() {
      let act = (this.role === '1' ? 'history' : 'wait');
      const {data: res} = await this.$http.get('getRepairOrders?act=' + act)
      if (res.code === 1) {
        let list = res.data.result
        let orderList = []
        this.status.forEach((element) => {
          orderList[element] = list.filter(function (item) {
            return item.status === element
          })
        })
        this.orderList = orderList
      }
    },
    //维修详情
    repairDetail(order_no) {
      this.$router.push('/detail?order_no=' + order_no)
    },
    //按钮操作
    operate(button_id, order_no, device_code, push_oa_status, estimated_price, repairPeopleNum, is_guobao, time_repair) {
      if (button_id === 1) {
        console.log('预估金额:' + estimated_price)
        //接单
        this.acceptOrder(order_no, device_code, push_oa_status, estimated_price, repairPeopleNum, is_guobao)
      } else if (button_id === 2) {
        //维修
        this.$router.push('/detail?order_no=' + order_no)
      } else if (button_id === 3) {
        //历史
        this.$router.push('/history?device_code=' + device_code)

      } else if (button_id === 4) {
        //竣工
        this.showCompleteOrderDialog(order_no, time_repair)
      } else if (button_id === 5) {
        //催单
        this.reminderOrder(order_no)
      } else if (button_id === 6) {
        //撤单
        this.showCancelOrderDialog(order_no)
      }

    },

    //确认是否联系
    confirmIsContact(order_no){
      let that = this
      this.$dialog.confirm({
        title: '是否联系',
        message: '您已确认联系维修人了吗？',
      })
          .then(async () => {
            that.$toast.loading({message:'确认中...'})
            const {data: res} = await that.$http.post('confirmContact', {order_no: order_no})
            if (res.code === 1) {
              that.$toast.success({
                message: '已确认',
                onClose() {
                  //刷新
                  that.$toast.loading({message: '刷新列表...'})
                  that.getRepairList()
                }
              })
            }
          })
          .catch(() => {
          });

    },

    //真实接单处理
    acceptOrderReal() {
      if (!this.lockGuaPai) {
        return this.$toast.fail({message: '您必须先打开挂牌上锁'})
      }
      let order_no = this.currentAcceptOrderNo
      // let is_guobao=this.currentIsGuobao
      this.acceptOrderDialogShow = false;
      if (this.estimated_price < 0) {
        return this.$toast.fail({message: '预估金额不能小于0'})
      }
      this.scanAndAccept(order_no, this.estimated_price, this.repairPeopleNum)
    },

    scanAndAccept(order_no, estimated_price, repairPeopleNum) {
      console.log('参与维修人数:' + repairPeopleNum)
      let that = this
      window.em.scanQRCode({
        scanType: ["qrCode", "barCode"],
        needResult: 1,
        async success(rr) {
          let code = getDeviceCode(rr.resultStr)
          if (code !== that.currentDeviceCode) {
            return that.$toast.fail({
              message: '设备编码不一致',
              onClose() {
                that.$route.go(-1)
              }
            })
          }
          //接单
          that.$toast.loading({message: '接单中...'})
          const {data: res} = await that.$http.get('isRepait?order_no=' + order_no)
          console.log(res)
          if (res.code === 1) {
            const {data: res1} = await that.$http.post('acceptOrder', {
              order_no: order_no,
              estimated_price: estimated_price,
              repair_people_num: repairPeopleNum
            })
            if (res1.code === 1) {
              //接单成功
              that.estimated_price = 0;//重置为0
              that.repairPeopleNum = 1
              that.$toast.success({
                message: res1.msg,
                onClose() {
                  //刷新
                  that.$toast.loading({message: '刷新列表...'})
                  that.getRepairList()
                }
              })
            } else {
              that.$toast.fail(res1.msg)
            }
          } else {
            that.$toast.fail(res.msg)
          }
        }
      });
    },

    //接单
    async acceptOrder(order_no, device_code, push_oa_status, estimated_price, repairPeopleNum, is_guobao) {
      this.currentDeviceCode = device_code
      if (push_oa_status === 3) {
        //oa大修申请通过 就直接扫码走下面 不用在弹框了
        this.scanAndAccept(order_no, estimated_price, repairPeopleNum)
      } else {
        this.acceptOrderDialogShow = true;
        this.currentAcceptOrderNo = order_no
        this.currentIsGuobao = is_guobao
      }
    },
    //催单
    reminderOrder(order_no) {
      this.$dialog.confirm({
        title: '催单',
        message: '确认发送催单通知到OA吗',
      })
          .then(async () => {
            const {data: res} = await this.$http.post('sendNoticeDeviceOrder', {order_no: order_no, type: 2})
            if (res.code === 1) {
              this.$toast.success('催单成功')
            }
          })
          .catch(() => {
          });
    },
    //竣工
    async completedOrder() {
      console.log(this.checkboxGroup)
      if (this.validateGroupChecked) {
        if (this.selectWorkGroupId === 0) {
          return this.$toast.fail('请选择班组')
        }
        if (this.workGroupEmployeeList.length === 0) {
          return this.$toast.fail('请选择班组人员')
        }
      }
      if (this.affectTime === '' || this.affectTime === 0) {
        return this.$toast.fail('请填写影响时间')
      }
      if (this.checkboxGroup.length === 0) {
        return this.$toast.fail('请选择评价')
      }
      let delay_employee_ids = this.workGroupEmployeeList.length === 0 ? '' : this.workGroupEmployeeList.map(item => {
        return item.id
      })
      let evaluates = this.checkboxGroup.join(',')
      const {data: res} = await this.$http.post('completedOrder', {
        order_no: this.completeOrder,
        evaluate: evaluates,
        workgroup_id: this.selectWorkGroupId,
        delay_employee_count: this.workGroupEmployeeList.length,
        delay_employee_ids: delay_employee_ids,
        delay_duration: this.affectTime
      })
      if (res.code === 1) {
        this.$toast.success(res.msg)
        this.selectWorkGroupId = 0
        this.workGroupEmployeeList = []
        this.affectTime = ''

        await this.getRepairList()
      } else {
        this.$toast.fail(res.msg)
      }
    },
    showCompleteOrderDialog(order_no, time_repair) {
      this.completeOrderDialogShow = true
      this.completeOrder = order_no
      this.completeOrderTimeRepair = time_repair
    },
    showCancelOrderDialog(order_no) {
      this.cancelOrderDialogShow = true
      this.cancelOrderNo = order_no
    },
    async cancelOrder() {
      if (this.cancelOrderNo === '') {
        this.$toast.fail('单号为空')
        return;
      }
      if (this.cancelReason === '') {
        this.$toast.fail('请填写撤单原因')
        return;
      }
      const {data: res} = await this.$http.post('cancelOrder', {
        order_no: this.cancelOrderNo,
        cancel_reason: this.cancelReason
      })
      if (res.code === 1) {
        this.cancelReason = '';
        this.cancelOrderNo = ''
        this.cancelOrderDialogShow = false
        this.$toast.success('撤单成功')
        await this.getRepairList()
      } else {
        this.$toast.fail(res.msg)
      }
    },
    hideCancelOrderDialog() {
      this.cancelOrderDialogShow = false
      this.cancelOrderNo = ''
      this.cancelReason = ''
    },
    async getEvaluteList() {
      const {data: res} = await this.$http.get('getDeviceEvaluate')
      if (res.code === 1) {
        res.data.device_evaluate.forEach(item => {
              item.forEach(item1 => {
                    this.evaluateList.push(item1)
                  }
              )
            }
        )
      }
    },
    //获取当前人的班组
    async getWorkGroupList() {
      const {data: res} = await this.$http.get('getWorkGroupList')
      if (res.code === 0) {
        this.$toast.fail(res.msg)
      } else {
        this.workGroupList = res.data.list
        this.getFlag = res.data.getFlag
      }
    },
    async getEmployeeList(work_group_id) {
      this.$toast.loading({message: '获取人员中...'})
      const {data: res} = await this.$http.get('getEmployeeList?getFlag=' + this.getFlag + '&send_repair_time=' + this.completeOrderTimeRepair + "&work_group_id=" + work_group_id)
      if (res.code === 0) {
        this.$toast.fail('该班组无人员,您可以联系排班人或者自己手动填入工号新增')
        this.workGroupEmployeeList = []
      } else {
        this.workGroupEmployeeList = res.data.employeeList
      }
    },
    removeEmployee(index) {
      this.workGroupEmployeeList.splice(index, 1)
    },
    //根据当前所选班组重置
    async resetEmployee() {
      this.$toast.loading({message: '重置中...'})
      if (this.selectWorkGroupId && this.completeOrderTimeRepair) {
        this.workGroupEmployeeList = []
        this.getEmployeeList(this.selectWorkGroupId)
      }
    },
    async confirmSelectWorkGroup(e) {
      this.selectWorkGroupName = e.group_name
      this.selectWorkGroupId = e.id
      this.workGroupSelectShow = false
      this.workGroupEmployeeList = []
      //根据班组和报修时间获取人员
      this.getEmployeeList(e.id)
    },
    async addEmployee() {
      if (this.card_id === '') {
        return this.$toast.fail('请填写新增人员的工号')
      }
      this.$toast.loading({message: '搜寻中...'})
      const {data: res} = await this.$http.get('getEmployeeByCardId?card_id=' + this.card_id)
      if (res.code === 0) {
        this.$toast.fail(res.msg)
      }
      let flag = 1
      this.workGroupEmployeeList.forEach(item => {
        if (item.id === res.data.employee.id) {
          flag = 2
        }
      })
      if (flag === 2) {
        return this.$toast.fail('该用户已存在')
      }
      this.workGroupEmployeeList.push(res.data.employee)
      this.card_id = ''
    }
  },


  created() {
    this.role = window.localStorage.getItem('role')
    this.getRepairList()
    this.getEvaluteList()
    this.getWorkGroupList()

  },
}
</script>

<style scoped>
.index-container {
  height: 100%;
  width: 100%;
}

.repair-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.repair-item {
  width: 90vw;
  background: #FFFFFF;
  border-radius: 5px;
  padding: 10px 10px 10px 10px;
  box-sizing: border-box;
  margin-top: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: start;
}

.label {
  font-size: 14px;
  font-weight: 300;
  width: 100%;
}

.button-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  margin-top: 5px;
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: -46px;
}

.push-to-oa-ing {
  font-size: 14px;
  color: cornflowerblue;
  padding: 2px;
  box-sizing: border-box;
  margin-top: 5px;
}

.push-to-oa-fail {
  font-size: 14px;
  color: crimson;
  padding: 2px;
  box-sizing: border-box;
  margin-top: 5px;
}

.push-to-oa-success {
  font-size: 14px;
  color: lightgreen;
  padding: 2px;
  box-sizing: border-box;
  margin-top: 5px;
}

.qrcode {
  width: 100%;
  margin-top: 10px;
  /*padding: 20px;*/
  display: flex;
  justify-content: center;
  align-items: center;
}

.employee-item {
  font-size: 0.373rem;
  color: #FFF;
  background: #77baef;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 2px;
  margin-top: 2px;
}

</style>