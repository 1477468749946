export const getDeviceCode=(url)=>{
    url=decodeURIComponent(url)
    console.log(url)
    let lastIndex=url.lastIndexOf('=');
    console.log(lastIndex)
    if (lastIndex===-1){
        return "";
    }
    return url.slice(lastIndex+1)
}
