import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import axios from "axios";
import "./plugins/vantui.js";
import 'vue-select/dist/vue-select.css';
import "./assets/css/global.css";
import 'amfe-flexible'
import  './utils/jem.js'
import vSelect from 'vue-select'



//正式服 外网地址
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;
// axios.defaults.baseURL = "http://58.221.197.202:181/api/oa/";
// axios.defaults.baseURL = "http://127.0.0.1:8080/api/oa/";
axios.interceptors.request.use((config) => {
  config.headers.Authorization = window.localStorage.getItem("token");
  return config;
});

axios.interceptors.response.use(function (res) {
  // 对响应数据做点什么
  if (res&&res.data) {
    if (res.data.code===-1){
      window.localStorage.clear()
      router.push('/login')
    }
    return  res
  }}
);

Vue.prototype.$http = axios; //  挂载

Vue.config.productionTip = false;
Vue.component('v-select', vSelect)

console.log(process.env.NODE_ENV)
console.log(process.env.VUE_APP_BASE_API)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
