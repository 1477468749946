<template>
  <div class="index-container">
    <!-- 大于1 可返回-->
    <van-nav-bar
        v-if="page_length>1"
        title="维修单详情"
        left-text="返回"
        left-arrow
        @click-left="navBack"
    />

    <van-nav-bar
        v-if="page_length===1"
        title="维修单详情"
        @click-left="backToIndex"
    >
      <template #left>
        <van-icon name="wap-home-o" size="20"/>
      </template>
    </van-nav-bar>

    <van-cell-group>
      <van-cell title="设备编号" :value="detail.device_code"/>
      <van-cell title="设备名称" :value="detail.device_name"/>
      <van-cell title="所在车间" :value="detail.device_dept_name"/>
      <van-cell title="是否过保修期" :value="detail.is_guobao?detail.is_guobao:''" style="color: red"
                :value-class="{'falut-desc':detail.is_guobao=='已过保'}"/>
      <van-cell title="报修人"
                :value="(detail.repair_user_info[0]?detail.repair_user_info[0]:'')+' '+(detail.repair_user_info[1]?detail.repair_user_info[1]:'')"/>
      <van-cell title="维修人"
                :value="(detail.accept_user_info[0]?detail.accept_user_info[0]:'')+' '+(detail.accept_user_info[1]?detail.accept_user_info[1]:'')"
                v-if="detail.accept_user_info"/>
      <van-cell title="故障类型" :value="repair_type_info_str"/>
      <van-cell title="报修时间" :value="detail.time_repair"/>
      <van-cell title="造成产品损坏" :value="detail.is_production_confirm_trash==1?'是':'否'"/>
      <van-cell title="接单时间" :value="detail.time_accept" v-if="detail.time_accept"/>
      <van-cell title="竣工时间" :value="detail.time_finish" v-if="detail.time_finish"/>
      <van-cell title="报修工单" :value="detail.order_no"/>
      <van-cell title="上道报修工单" :value="detail.parent_order_no" v-if="detail.parent_order_no"/>
      <van-cell title="工单状态" :value="(dict[detail.status]&&dict[detail.status].name)?dict[detail.status].name:''"
                :value-class="detail.status+'-color'"/>
      <van-cell title="报修图片" value="" v-if="detail.repair_imgs"/>
      <div class="img-list" v-if="detail.repair_imgs&&detail.repair_imgs.length>0">
        <van-image
            class="fault-img"
            :src="item"
            v-for="item in detail.repair_imgs" :key="item"
            @click="previewImg"
        />
      </div>
      <template v-if="detail.status==='completed'||detail.status==='finish'">
        <van-cell title="维修图片" value="" v-if="detail.accept_imgs"/>
        <div class="img-list" v-if="detail.accept_imgs&&detail.accept_imgs.length>0">
          <van-image
              class="fault-img"
              :src="item"
              v-for="item in detail.accept_imgs" :key="item"
              @click="previewImg"
          />
        </div>
        <van-cell v-if="detail.assist_employees!=''" title="协助维修人员" :value="detail.assist_employees"/>

        <van-cell title="故障描述" :value="detail.depict" v-if="detail.depict" style="color: red"
                  :value-class="'falut-desc'"/>
        <van-cell title="故障分类" :value="(detail.repair_cause&&detail.repair_cause.name)?detail.repair_cause.name:''"
        />
        <van-cell title="故障原因"
                  :value="(detail.repair_cause&&detail.repair_cause.detail)?detail.repair_cause.detail :''"/>
        <van-cell title="维修方式" :value="detail.method==null?'':detail.method"/>
        <van-cell title="更换备件" :value="detail.change_replacement==null?'':detail.change_replacement"/>

        <van-cell v-if="detail.evaluate!=''" title="评价" :value="detail.evaluate"/>
      </template>
    </van-cell-group>

    <van-form @submit="onSubmit" v-if="detail.status==='under-repair'&&role==='2'">
      <van-field name="uploader" label="维修图片">
        <template #input>
          <van-uploader v-model="uploadFiles" :before-read="beforeRead"
                        :accept="'image/png, image/jpeg,image/jpg,image/tmp'" :multiple="true"/>
        </template>
      </van-field>
      <van-field
          v-model="formData.programme"
          rows="4"
          autosize
          label="故障解决办法"
          type="textarea"
          placeholder="请输入故障解决办法"
          maxlength="600"
      />
      <van-field
          v-model="formData.method"
          rows="4"
          autosize
          label="维修方式"
          type="textarea"
          placeholder="请输入维修方式"
          maxlength="600"
      />
      <van-field
          v-model="formData.change_replacement"
          rows="4"
          autosize
          label="更换备件"
          type="textarea"
          placeholder="请输入更换备件"
          maxlength="600"
      />
      <van-field
          readonly
          clickable
          name="picker"
          :value="OptionType?(OptionType[OptionTypeIndex].name):'请选择'"
          label="故障分类"
          placeholder="点击选择故障分类"
          @click="showOptionTypePicker = true"
      />
      <van-popup v-model="showOptionTypePicker" position="bottom">
        <van-picker
            show-toolbar
            :columns="OptionTypeName"
            @confirm="onConfirmOptionType"
            @cancel="showOptionTypePicker = false"
        />
      </van-popup>
      <van-field
          v-model="formData.reason"
          rows="4"
          autosize
          label="故障原因"
          type="textarea"
          placeholder="请输入故障原因"
          maxlength="600"
      />


      <div style="flex-direction: row;display: flex;align-items: center;background-color: #FFF;padding: 0.26667rem 0.42667rem;">
        <div style="font-size: 0.37333rem;color: #646566;line-height: 0.64rem">协助维修人员</div>
        <v-select
            v-model="selectedXiezhuRepairMembers"
            multiple
            placeholder="请选择协助维修人员,最多10人"
            label="label"
            :options="repair_members"
            :selectable="() => selectedXiezhuRepairMembers.length < 10"
        />
      </div>


      <div v-if="otherPeopleModal">
        <van-field
            readonly
            clickable
            name="picker"
            :value="formData.other.repair_people.name"
            label="指定维修人"
            placeholder="请选择"
            @click="assignRepiarUserModal = true"
        />
        <van-popup v-model="assignRepiarUserModal" position="bottom">
          <van-picker
              show-toolbar
              :value-key="'name'"
              :columns="repair_teams_list"
              @confirm="selectAssignRepairUser"
              @cancel="assignRepiarUserModal = false"
          />
        </van-popup>


        <van-field
            readonly
            clickable
            name="picker"
            :value="formData.other.accept_people.name"
            label="指定验收人"
            placeholder="请选择"
            @click="assignAcceptUserModal = true"
        />
        <van-popup v-model="assignAcceptUserModal" position="bottom">
          <van-picker
              show-toolbar
              :value-key="'name'"
              :columns="accept_teams_list"
              @confirm="selectAssignAcceptUser"
              @cancel="assignAcceptUserModal = false"
          />
        </van-popup>
      </div>
      <div class="button-group">
        <van-button type="info">完成维修</van-button>
        <van-button type="warning" native-type="button" @click="oaRepairApply">推送OA</van-button>
        <van-button type="danger" native-type="button" @click="assignRepiarUser">指定维修人</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {ImagePreview} from "vant";

export default {

  data() {
    return {
      repair_members: [],
      selectedXiezhuRepairMembers: [],

      otherPeopleModal: false,
      repair_teams_list: [],
      assignRepiarUserModal: false,

      accept_teams_list: [],
      assignAcceptUserModal: false,

      page_length: 1,//页面栈深度

      detail: {
        device_code: "",
        device_name: "",
        device_dept_name: "",
        repair_user_info: [],
        accept_user_info: [],
        repair_type_info: {},
        order_no: '',
        parent_order_no: null,
        time_repair: null,
        time_accept: null,
        time_finish: null,
        repair_imgs: [],
        depict: "",
        programme: "",
        repair_cause: {
          name: '',
          detail: ''
        },
        method: null,
        change_replacement: null
      },

      dict: {
        'wait': {"name": "等待接单中", "color": "#FF6600"},
        'under-repair': {'name': "正在维修中", 'color': "#FF0033"},
        'cancel': {'name': "已撤单", 'color': "#FF0000"},
        'completed': {'name': "已完成", "color": "#006600"},
        'finish': {'name': '维修完成', 'color': "#F57527"}
      },

      OptionType: null,
      OptionTypeName: [],
      OptionTypeIndex: 0,
      showOptionTypePicker: false,

      uploadFiles: [],
      //表单数据
      formData: {
        order_no: '',
        programme: '',//
        method: '',
        reason: '',
        change_replacement: '',
        repair: {
          cause: {}
        },
        other: {
          repair_people: {},//指定维修人
          accept_people: {}//指定验收人
        },
        accept_imgs: []
      },
    };
  },

  methods: {
    navBack() {
      this.$router.go(-1)
    },
    backToIndex() {
      this.$router.replace('/index')
    },
    previewImg() {
      ImagePreview(this.detail.repair_imgs)
    },
    //选择故障分类
    onConfirmOptionType(e) {
      this.OptionType.forEach((element, index) => {
        if (element.name === e) {
          this.OptionTypeIndex = index
          this.formData.repair.cause = this.OptionType[this.OptionTypeIndex]
        }
      })
      this.showOptionTypePicker = false
      console.log(this.formData.repair.cause)
    },
    //获取详情
    async getDetail(order_no) {
      const {data: res} = await this.$http.get('detail?order_no=' + order_no)
      if (res.code === 1) {
        this.detail = res.data.result
        this.formData.method = this.detail.method
        this.formData.reason = this.detail.repair_cause.detail
      } else {
        this.$toast.fail('网络错误')
      }
    },
    //获取故障分类
    async getDeviceOptionType() {
      const {data: res} = await this.$http.get('getDeviceOptionType')
      if (res.code === 1) {
        let OptionType = res.data.result.OptionType
        let OptionTypeName = []
        OptionType = OptionType.filter(function (item) {
          return item.cid === 2
        })
        OptionType.forEach((element) => {
          OptionTypeName.push(element.name)
        })
        this.OptionType = OptionType
        this.OptionTypeName = OptionTypeName
        if (OptionType) {
          this.formData.repair.cause = OptionType[0]
        }
      }
    },

    beforeRead(file) {
      console.log(file)
      return true
    },

    //大修申请
    oaRepairApply() {
      let is_guobao = this.detail.is_guobao
      let order_no = this.detail.order_no
      let push_oa_status = this.detail.push_oa_status
      let that = this
      if (is_guobao == '') {
        return this.$toast.fail({message: '该设备保修天数或者购买日期未填写，请联系设备部'})
      }
      if (push_oa_status == 1 || push_oa_status == 3) {
        return this.$toast.fail({message: '该设备已推送oa或已被oa大修'})
      }
      if (push_oa_status == 2 && this.pushToOa) {
        return this.$toast.fail({message: '该设备被oa认定为小修,不可再推至oa'})
      }
      this.$dialog.confirm({
        title: '发起大修',
        message: '此维修单将推送至OA,发起大修流程',
      })
          .then(async () => {
            that.$toast.loading({message: '推送oa中...'})
            const {data: res} = await that.$http.post('pushToOa', {
              order_no: order_no,
              is_guobao: is_guobao == '已过保' ? 1 : 0
            })
            if (res.code === 1) {
              that.$toast.success({
                message: res.msg, onClose() {
                  that.$router.go(-1)
                }
              })
            } else {
              that.$toast.fail({message: res.msg})
            }
          })
    },


    onSubmit() {
      this.$dialog.confirm({
        title: '完成维修',
        message: '确认完成维修吗',
      })
          .then(async () => {
            console.log(this.uploadFiles);
            let selectedXiezhuRepairMemberIds = this.selectedXiezhuRepairMembers.map(item => item.uid);
            this.selectedXiezhuRepairMemberIds=selectedXiezhuRepairMemberIds
            if (this.uploadFiles.length > 0) {
              this.$toast.loading({message: '上传图片中'})
            }
            this.uploadRepairImgs(this.uploadFiles, 0)
          })
          .catch(() => {
          });
    },
    async uploadRepairImgs(uploadFiles, i) {
      if (uploadFiles.length === i) {
        //上传图片完毕
        this.$toast.clear()
        setTimeout(() => {
          this.$toast.loading({message: '提交表单数据'})
          this.saveFormData()
        }, 1000)
        return;
      }
      let data = new FormData();
      data.append('DingDingUpFile', uploadFiles[i].file)
      const {data: res} = await this.$http.post('uploadDeviceRepairFile', data)
      if (res.code === 1) {
        this.formData.accept_imgs.push(res.data.url)
      } else {
        console.log('上传失败,忽略')
      }
      await this.uploadRepairImgs(uploadFiles, i + 1)
    },
    async saveFormData() {
      this.formData.order_no = this.detail.order_no
      this.formData.assist_employee_ids = this.selectedXiezhuRepairMemberIds //协助维修人员
      this.formData.repair.cause.detail = this.formData.reason
      //保存数据
      console.log(this.formData)
      console.log('提交表单数据')
      const {data: res} = await this.$http.post('devicePublishProgramme', this.formData)
      if (res.code === 1) {
        this.$toast.success('已完成')
        setTimeout(() => {
          this.$router.go(-1)
        })
      } else {
        this.$toast.fail(res.msg)
      }
    },
    //指定维修人
    assignRepiarUser() {
      if (this.otherPeopleModal) {
        this.otherPeopleModal = false
        this.formData.other = {
          repair_people: {},//指定维修人
          accept_people: {}//指定验收人
        }
      } else {
        this.otherPeopleModal = true
      }
      console.log('123')
    },
    async getRepairPeopleList(order_no) {
      const {data: res} = await this.$http.get('getRepairPeopleList?order_no=' + order_no)
      if (res.code === 1) {
        this.accept_teams_list = res.data.data.accept_teams_list
        this.repair_teams_list = res.data.data.repair_teams_list
        console.log(this.repair_teams_list)
        console.log(res.data)
      }
    },
    async getRepairMembers() {
      const {data: res} = await this.$http.get('getRepairMembers')
      if (res.code === 1) {
        this.repair_members = res.data.deviceMembers
        console.log(this.repair_members, '维修人员')
      }
    },
    selectAssignRepairUser(e) {
      console.log(e)
      this.formData.other.repair_people = e
      this.assignRepiarUserModal = false
    },
    selectAssignAcceptUser(e) {
      console.log(e)
      this.formData.other.accept_people = e
      this.assignAcceptUserModal = false
    }
  },
  computed: {
    'repair_type_info_str': function () {
      let str = '';
      if (this.detail.repair_type_info.name) {
        str += this.detail.repair_type_info.name
      }
      if (this.detail.repair_type_info.sub && this.detail.repair_type_info.sub.name) {
        str += this.detail.repair_type_info.sub.name
      }
      return str
    },
    'isFinish': function () {
      return this.role === '1' && this.detail.status === 'under-repair';
    },
  },
  created() {
    console.log(window.history.length)
    this.page_length = window.history.length
    let order_no = this.$route.query.order_no
    this.getDetail(order_no)
    this.getDeviceOptionType()
    this.getRepairPeopleList(order_no)
    this.getRepairMembers()//获取协助维修的人
    this.role = window.localStorage.getItem('role')
  }
}
</script>

<style scoped>
/*//        'wait': {"name": "等待接单中", "color": "#FF6600"},*/
/*'under-repair':{'name':"正在维修中",'color':"#FF0033"},*/
/*'cancel':{'name':"已撤单",'color':"#FF0000"},*/
/*'completed':{'name':"已完成","color":"#006600"},*/
/*'finish':{'name':'维修完成','color':"#F57527"}*/
.wait-color {
  color: #FF6600;
}

.under-repair-color {
  color: #FF0033;
}

.cancel-color {
  color: #FF0000;
}

.completed-color {
  color: #006600;
}

.finish-color {
  color: #F57527;
}

.falut-desc {
  color: red;
}

.index-container {
  height: 100%;
  width: 100%;
}

.img-list {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
  box-sizing: border-box;
}

.fault-img {
  width: 81.25px;
  height: 81.25px;
  margin-left: 16px;
  margin-top: 10px;
}

.button-group {
  display: flex;
  padding: 10px 20px;
  flex-direction: row;
  justify-content: space-evenly;
}

.v-select{
  width: 100%;
}

::v-deep .vs__selected-options {
  font-size: 0.3rem !important;

}



::v-deep .vs__dropdown-menu .vs__dropdown-option {
  font-size: 0.6rem !important;

}

>>> {
  --vs-font-size:0.4rem;
}
</style>