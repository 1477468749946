<template>
  <div class="index-container">
    <van-nav-bar
        title="设备中心"
        left-text="返回"
        left-arrow
        @click-left="navBack"
    />
    <canvas id="qrcanvas" style="display: none"></canvas>
    <div class="item-container">
      <div class="item" @click="scan">
        <van-icon name="scan"/>
        <div class="item-descrip">扫一扫</div>
      </div>
<!--      关闭选择和输入模式 用扫一扫-->
<!--      <div class="item" style="position: relative" >-->
<!--        <input id="upload" type="file" accept="image/*;" ref="picers" class="selectFile" @change="chooseImg()">-->
<!--        <van-icon name="photo"/>-->
<!--        <div class="item-descrip">选择二维码</div>-->
<!--      </div>-->
<!--      <div class="item" @click="inputDeviceCode">-->
<!--        <van-icon name="edit"/>-->
<!--        <div class="item-descrip">输入设备码</div>-->
<!--      </div>-->
      <div class="item" @click="repairList">
        <van-icon name="wap-nav"/>
        <div class="item-descrip">报修列表</div>
      </div>
    </div>
    <van-dialog v-model="deviceCodeShow" title="设备编码" show-cancel-button @confirm="confirmDeviceCode" @cancel="cancelDeviceCode">
      <van-field v-model="deviceCode" label="" placeholder="请输入设备编码"/>
    </van-dialog>
  </div>
</template>

<script>
// import jsQR from 'jsqr';
import jsQR from "jsqr";
import $ from "jquery"
import {getDeviceCode} from "../utils/device"


export default {
  data() {
    return {
      deviceCodeShow:false,
      deviceCode:''
    };
  },
  methods: {
    navBack() {
      this.$router.go(-1)
    },
    scan(){
      let that=this
      window.em.scanQRCode({
        scanType: ["qrCode", "barCode"],
        needResult:1,
        success: function(res) {
          let code=getDeviceCode(res.resultStr)
          console.log(code)
          that.$router.push('/device_info?device_code='+code)
        }
      });
    },
    repairList() {
      this.$router.push('/repair_list')
    },
    chooseImg() {
      let that=this
      this.$toast.loading({
        'message':"识别二维码..."
      })
      var inputDOM = this.$refs.picers;
      var qrcodeInfo = inputDOM.files[0];
      var fr = new FileReader();
      fr.readAsDataURL(qrcodeInfo);
      fr.onloadend = function(e) {
        var base64Data = e.target.result;
        that.base64ToqR(base64Data)
      }
      // code = jsQR(imageData.data, imageData.width, imageData.height);
    },
    base64ToqR(data) {
      let that=this
      var c = document.getElementById("qrcanvas");
      var ctx = c.getContext("2d");
      var img = new Image();
      img.src = data;
      img.onload = function () {
        $("#qrcanvas").attr("width", img.width)
        $("#qrcanvas").attr("height", img.height)
        ctx.drawImage(img, 0, 0, img.width, img.height);
        var imageData = ctx.getImageData(0, 0, img.width, img.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: "dontInvert",
        });
        if (code) {
          console.log("Found QR code", code.data);
          let deviceCode=getDeviceCode(code.data)
          console.log(deviceCode)
          that.$toast.clear()
          that.$router.push('/device_info?device_code='+deviceCode)
        } else {
          console.log('无效的二维码')
          that.$toast.fail('无效的二维码')
        }
      };
    },
    //输入设备编号
    inputDeviceCode(){
      this.deviceCodeShow=true

    },
    cancelDeviceCode(){
      this.deviceCodeShow=false
      this.deviceCode=''
    },
    //确认
    async confirmDeviceCode(){
      if (this.deviceCode===''){
        return this.$toast.fail('请输入设备编码')
      }
      this.$router.push('/device_info?device_code='+this.deviceCode)
    }
  },
  created() {
    let role=window.localStorage.getItem('role')
    this.role=role
  }
}
</script>

<style scoped>
.selectFile {
  width: 100%;
  height: 100%;
  z-index: 9999;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.index-container {
  height: 100%;
  width: 100%;
}

.item {
  width: 30vw;
  height: 35vw;
  margin-left: 2.5vw;
  margin-top: 2.5vw;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 2vw;
}

.item-descrip {
  font-size: 14px;
  margin-top: 10px;
}

.item-container {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-content: start;
  box-sizing: border-box;
}
</style>